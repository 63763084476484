$font-path: '../fonts/geometria/';

@font-face {
    font-family: 'geometria';
    src: url($font-path + 'geometria.eot');
    src: url($font-path + 'geometria.eot?#iefix') format('embedded-opentype'), url($font-path + 'geometria.woff') format('woff'), url($font-path + 'geometria.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap; }

@font-face {
    font-family: 'geometria';
    src: url($font-path + 'geometria_medium.eot');
    src: url($font-path + 'geometria_medium.eot?#iefix') format('embedded-opentype'), url($font-path + 'geometria_medium.woff') format('woff'), url($font-path + 'geometria_medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap; }

@font-face {
    font-family: 'geometria';
    src: url($font-path + 'geometria_extrabold.eot');
    src: url($font-path + 'geometria_extrabold.eot?#iefix') format('embedded-opentype'), url($font-path + 'geometria_extrabold.woff') format('woff'), url($font-path + 'geometria_extrabold.ttf') format('truetype');
    font-weight: 800;
    font-stylheavy {}
    font-display: swap; }

@font-face {
    font-family: 'geometria';
    src: url($font-path + 'geometria_heavy.eot');
    src: url($font-path + 'geometria_heavy.eot?#iefix') format('embedded-opentype'), url($font-path + 'geometria_heavy.woff') format('woff'), url($font-path + 'geometria_heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap; }
